<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ListGuru from "@/router/views/guru/listGuru";
import Create from "@/router/views/guru/create";

/**
 * Starter component
 */
export default {
  page: {
    title: "Data Guru Pondok",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader,ListGuru,Create },
  data() {
    return {
      title: "Data Guru Pondok",
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Data Guru",
          active: true
        }
      ],
    };
  },
  computed: {
    // a computed getter
    state: function () {
      return this.$store.getters["guru/states"];
    },
    notif: function () {
      return this.$store.getters["guru/notif"];
    }
  },
  methods : {
      create(){
          this.$store.commit("guru/SET_STATE","CREATE")
      }
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
        <b-alert show dismissible fade v-show="notif != null">{{notif}}</b-alert>
        <div class="col-md-12">
            <button class="btn btn-pill btn-primary mb-2" v-show="state == 'LIST'" @click="create()"> <i class="bx bx-user-plus"></i> Tambah Data Guru</button>
        </div>
        <div class="col-md-12"  v-show="state == 'LIST'">
            <ListGuru />
        </div>
        <div class="col-md-12" v-show="state == 'CREATE'">
            <Create />
        </div>
    </div>
  </Layout>
</template>