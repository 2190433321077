<script>

/**
 * Contacts-list component
 */
export default {
  data() {
    return {
        nama : "",
        nip : "",
        email : "",
        phone : "",
        password : "",

    };
  },
  computed: {
    // a computed getter
    loading: function () {
      return this.$store.getters["guru/loading"];
    }
  },
  methods : {
      simpan(){
          this.$store.dispatch("guru/createGuru",{
              nama:this.nama,
              nip:this.nip,
              email:this.email,
              phone:this.phone,
              password:this.password,
            })
      },
      cancel(){
          this.$store.commit("guru/SET_STATE","LIST")
      }
  }
};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label">Nama Guru : </label>
                        <b-form-input v-model="nama" placeholder="Masukan Nama Guru"></b-form-input>
                    </div>
                    <div class="form-group">
                        <label class="control-label">NIP Guru : </label>
                        <b-form-input  v-model="nip" placeholder="Masukan NIP Guru"></b-form-input>
                    </div>
                    <div class="form-group">
                        <label class="control-label">Email Guru : </label>
                        <b-form-input  :type="'email'" v-model="email" placeholder="Masukan NIP Guru"></b-form-input>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label">No Telp Guru : </label>
                        <b-form-input  v-model="phone" placeholder="Masukan No Telp Guru"></b-form-input>
                    </div>
                    <div class="form-group">
                        <label class="control-label">Password login Guru : </label>
                        <b-form-input :type="'password'" v-model="password" placeholder="Masukan Password Guru"></b-form-input>
                    </div>
                </div>
                <div class="col-md-12">
                    <button class="btn btn-danger btn-sm mx-2" @click="cancel()">Cancel</button>
                    <button class="btn btn-success btn-sm mx-2" :disabled="loading" @click="simpan()"> <i class="bx bx-loader bx-spin" v-show="loading"></i>  Simpan</button>
                </div>
            </div>
        </div>
    </div>
</template>