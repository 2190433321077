<script>

/**
 * Contacts-list component
 */
import Swal from "sweetalert2";
export default {
  data() {
    return {
      title: "User List",
    };
  },
  computed: {
    // a computed getter
    gurus: function () {
      return this.$store.getters["guru/items"];
    }
  },
  mounted(){
      this.$store.dispatch("guru/getGuru");
  },
  methods : {
    confirm(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Yang Di hapus Tidak dapat di kembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Ya, Hapus !"
      }).then(result => {
        if (result.value) {
          this.$store.dispatch("guru/hapusGuru",id);
          Swal.fire("Deleted!", "Berhasil Data Akan Segera Terhapus.", "success");
          this.$store.dispatch("guru/getGuru");
        }else{
          Swal.fire("Cancel!", "Data Tidak Terhapus", "error");
          this.$store.dispatch("guru/getGuru");
        }
      });
    },
  }
};
</script>

<template>
    <div class="card">
        <div class="card-body">
        <div class="table-responsive">
            <table class="table table-centered table-nowrap table-hover">
            <thead class="thead-light">
                <tr>
                <th scope="col" style="width: 70px;">#</th>
                <th scope="col">Nama Guru</th>
                <th scope="col">NIP</th>
                <th scope="col">Email</th>
                <th scope="col">No Telp</th>
                <th scope="col">Total Koreksi</th>
                <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="list in gurus" :key="list.id">
                    <td>
                      <div class="avatar-xs">
                        <span class="avatar-title rounded-circle">T</span>
                      </div>
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-1">
                        <a href="#" class="text-dark">{{list.nama}}</a>
                      </h5>
                    </td>
                    <td>{{list.nip}}</td>
                    <td>{{list.email}}</td>
                    <td>{{list.phone}}</td>
                    <td>{{list.dataKoreksi}}</td>
                    <td>
                        <button class="btn btn-warning btn-sm mx-1"  v-b-tooltip.hover title="Edit"><i class="bx bx-edit"></i></button>
                        <button class="btn btn-danger btn-sm  mx-1" @click="confirm(list.id)" v-b-tooltip.hover title="Delete" ><i class="bx bx-trash"></i></button>
                    </td>
                </tr>
            </tbody>
            </table>
        </div>
        </div>
    </div>
</template>